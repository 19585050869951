export const linkCorousel = [
  {
    id: 1,
    imageUrl: "models/M5.jpg",
    buttonText: "PRODUCTS",
    navigate: "/products",
    description:
      "Discover Our Products. Explore our latest products and offerings that are sure to catch your eye.",
  },
  {
    id: 2,
    imageUrl: "models/M6.jpg",
    buttonText: "ABOUT US",
    navigate: "/about",
    description:
      "Learn About Us. Get to know our story and what sets us apart from the rest.",
  },
  {
    id: 3,
    imageUrl: "models/M7.jpg",
    buttonText: "CONTACT US",
    navigate: "/contact",
    description:
      "Get in Touch. Reach out to us for any inquiries or to simply say hello.",
  },
  {
    id: 4,
    imageUrl: "models/M8.jpg",
    buttonText: "SAVING SCHEME",
    navigate: "/saving-scheme",
    description:
      "Start Saving Today. Begin your journey to financial freedom with our exclusive saving schemes.",
  },
];

export const bestSeller = [
  {
    id: 1,
    imageUrl: "best-sellers/c5.jpg",
    name: "ANTIQUE",
  },
  {
    id: 2,
    imageUrl: "best-sellers/c4.jpg",
    name: "UNCUT",
  },
  {
    id: 3,
    imageUrl: "best-sellers/c3.jpg",
    name: "SILVER",
  },
  // {
  //   id: 4,
  //   imageUrl: "best-sellers/c2.jpg",
  //   name: "VICTORIAN",
  // },
  {
    id: 5,
    imageUrl: "best-sellers/c1.jpg",
    name: "KUNDAN JADAU",
  },
];
